/**
 * Formats array of strings to objects fit for use as options with FormDropdown.
 * @param {Array} arr The array of options.
 * @return {Array} The formatted array of options as objects.
 */
module.exports = (arr) => {
  return Array.from(['', ...arr], (opt) => {
    return typeof opt === 'string'
      ? {
          id: opt,
          name: opt,
          value: opt,
        }
      : opt
  })
}
