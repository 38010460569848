import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Hero from 'components/Hero'
import Layout from 'components/Layout'
import SectionHeader from 'components/SectionHeader'
import TravelRegistration from 'components/TravelRegistration'
import { Spacing100 } from 'styles/sharedStyle'
import { formVillageOptions } from 'utils/formVillageOptions'

import { constructUrlPath } from 'utils/urlUtils'

const TravelRegistrationPageT10 = (props) => {
  const {
    pageContext: {
      recaptchaKey,
      nodeLocale,
      villageSlug,
      pageLevel,
      translatedSlugs,
      translatedServices,
    },
    data: {
      allVillages: { edges: allVillages },
      page: { formMetaData, hero, locale, jsonLdSchema, village },
    },
    location: { origin: locOrigin },
  } = props

  const formLabels = Object.assign(
    {
      preferredVillageOptions: formVillageOptions(allVillages),
    },
    formMetaData &&
      formMetaData.find(
        (metaData) =>
          metaData.__typename === 'ContentfulLabelTravelRegistrationLabelsLab10'
      )
  )

  const sectionHeader =
    formMetaData &&
    formMetaData.find(
      (metaData) => metaData.__typename === 'ContentfulCompSectionHeaderSch01'
    )

  const sectionHeaderProps = sectionHeader
    ? {
        eyebrow: sectionHeader.eyebrow ? sectionHeader.eyebrow : null,
        title: sectionHeader.headline ? sectionHeader.headline : null,
        content: sectionHeader.bodyCopy ? sectionHeader.bodyCopy : null,
        isPageHeader: true,
        withMaxWidth: true,
        background: 'Grey',
      }
    : null

  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
      }
    : {}
  const heroProps = hero
    ? {
        eyebrow: hero.eyebrow,
        content: hero.headline,
        image: heroImage,
        video: heroVideo,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  const url = formMetaData
    ? constructUrlPath({
        nodeLocale,
        pageLevel,
        translatedServices,
        translatedSlugs,
        url: formMetaData[0].successUrl,
        villageSlug,
      })
    : ''

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>
      {heroProps && <Hero {...heroProps} />}
      {sectionHeader && (
        <>
          <Spacing100 />
          <SectionHeader {...sectionHeaderProps} />
        </>
      )}
      <Spacing100 />
      {formMetaData && (
        <TravelRegistration
          {...{ recaptchaKey, locale, url, ...formLabels }}
          locOrigin={locOrigin}
        />
      )}
    </Layout>
  )
}

TravelRegistrationPageT10.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    origin: PropTypes.string,
  }).isRequired,
  pageContext: PropTypes.shape({
    recaptchaKey: PropTypes.string,
    nodeLocale: PropTypes.string,
    pageLevel: PropTypes.string,
    translatedServices: PropTypes.instanceOf(Object),
    translatedSlugs: PropTypes.instanceOf(Object),
    villageSlug: PropTypes.string,
  }).isRequired,
}

export const TravelRegistrationQuery = graphql`
  query(
    $id: String!
    $openingHoursId: String!
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $pageTypeSlug: String
    $villageSlug: String
  ) {
    page: contentfulPageTemplateFormsT10(id: { eq: $id }) {
      jsonLdSchema {
        jsonLdSchema
      }
      id
      hero {
        eyebrow
        headline
        ...heroMediaQuery
      }
      locale: node_locale
      formMetaData {
        __typename
        ... on Node {
          ... on ContentfulLabelTravelRegistrationLabelsLab10 {
            headline
            titleLabel
            titleOptions
            firstNameLabel
            lastNameLabel
            emailLabel
            confirmEmailLabel
            companyNameLabel
            websiteLabel
            typeOfBusinessLabel
            typeOfBusinessOptions
            mobileLabel
            faxNumberLabel
            countryOfResidenceLabel
            countryOfResidenceOptions
            preferredVillageLabel
            commentsLabel
            consentLabel {
              childMarkdownRemark {
                html
              }
            }
            ctaLabel
            requiredValidationMessage
            emailValidationMessage
            numberValidationMessage
            consentValidationMessage
            successUrl
          }
        }
      }
      village {
        name
        code
        currency
        emailRecipient
        villageSlug: slug
        servicesHeader {
          ...header
        }
        home: page_template_home_t01 {
          ...headerFooterVillage
        }
        openingHours {
          ...villageOpeningHours
        }
        defaultLocale
        openingStatusLabel
      }
      pageTitle
      pageDescription
      hideFromSearchEngine
    }
    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          ...contentfulOpeningHoursExceptions
        }
      }
    }
    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }
    allVillages: allContentfulEntityVillageEnt01(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          name
        }
      }
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
    allMembershipPopUps: allContentfulCompMembershipPopUp(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
        active: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          ...membershipPopUp
        }
      }
    }
  }
`

export default TravelRegistrationPageT10
