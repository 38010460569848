import styled from 'styled-components'
import { Title60 } from 'components/Typography'
import Button from 'components/Button'
import theme from 'styles/theme'

export const Wrapper = styled.section`
  color: ${theme.colors.sagedark};
  margin: 0 0 60px;
  padding: 0 20px;
`

export const Center = styled.div`
  ${theme.mediaQueries.medium} {
    margin: 0 auto;
    max-width: 780px;
  }
`

export const Headline = styled(Title60)`
  margin: 0 0 30px;
  text-align: center;
`

export const FormGrid = styled.div`
  ${theme.mediaQueries.medium} {
    display: flex;
    justify-content: space-between;
  }
`

export const FormColumn = styled.div`
  ${theme.mediaQueries.medium} {
    flex: none;
    width: calc(50% - 10px);
  }
`

export const Consent = styled.div`
  margin-top: 20px;

  p {
    margin: 0;

    p {
      align-self: center;
    }
  }
`

export const Actions = styled.div`
  margin-top: 60px;

  .grecaptcha-badge {
    z-index: 1;
  }
`

export const SubmitBtn = styled(Button)`
  cursor: pointer;
  display: block;
  height: 60px;
  margin: 0 auto;
  max-width: 380px;
`
