export default function absoluteUrl(path, root) {
  let href = path
  if (href.indexOf('http') !== 0) {
    const pathname = path.replace(/^\//, '')
    const origin =
      root || (typeof window !== 'undefined' && window.location.origin)
    href = `${origin}/${pathname}`
  }
  return href
}
