import React, { useState } from 'react'
import PropTypes from 'prop-types'
import absoluteUrl from 'utils/absoluteUrl'
import { validations } from 'utils/form-validation'
import formOptionFormat from 'utils/formOptionFormat'
import {
  FormCheckbox,
  FormDropdown,
  FormText,
  FormTextarea,
  useForm,
  Recaptcha,
} from 'components/Forms'

import {
  Actions,
  Center,
  Consent,
  FormColumn,
  FormGrid,
  Headline,
  SubmitBtn,
  Wrapper,
} from './TravelRegistration.style'

const TravelRegistration = ({
  commentsLabel,
  companyNameLabel,
  confirmEmailLabel,
  consentLabel,
  consentValidationMessage,
  countryOfResidenceLabel,
  countryOfResidenceOptions,
  ctaLabel,
  emailLabel,
  emailValidationMessage,
  faxNumberLabel,
  firstNameLabel,
  headline,
  lastNameLabel,
  locale,
  locOrigin,
  mobileLabel,
  preferredVillageLabel,
  preferredVillageOptions,
  recaptchaKey,
  requiredValidationMessage,
  titleLabel,
  titleOptions,
  typeOfBusinessLabel,
  typeOfBusinessOptions,
  url,
  websiteLabel,
}) => {
  const { notNull, stringNotNullOrEmpty, validateEmail } = validations()

  const requiredField = {
    value: '',
    errorMessage: requiredValidationMessage,
    validation: stringNotNullOrEmpty,
  }

  const reqTextField = {
    Component: FormText,
    ...requiredField,
  }

  const reqDropdown = {
    Component: FormDropdown,
    ...requiredField,
  }

  const fields = {
    title: {
      name: 'title',
      queryName: 'salutation',
      label: `${titleLabel} *`,
      options: formOptionFormat(titleOptions),
      ...reqDropdown,
    },
    firstName: {
      name: 'firstName',
      queryName: 'first_name',
      label: `${firstNameLabel} *`,
      ...reqTextField,
    },
    lastName: {
      name: 'lastName',
      queryName: 'last_name',
      label: `${lastNameLabel} *`,
      ...reqTextField,
    },
    email: {
      name: 'email',
      queryName: 'email',
      label: `${emailLabel} *`,
      value: '',
      errorMessage: emailValidationMessage,
      validation: validateEmail,
      Component: FormText,
    },
    confirmEmail: {
      name: 'confirmEmail',
      label: `${confirmEmailLabel} *`,
      value: '',
      errorMessage: emailValidationMessage,
      validation: validateEmail,
      Component: FormText,
    },
    companyName: {
      name: 'companyName',
      queryName: 'company',
      label: `${companyNameLabel} *`,
      ...reqTextField,
    },
    website: {
      name: 'website',
      queryName: 'url',
      label: `${websiteLabel} *`,
      ...reqTextField,
    },
    typeOfBusiness: {
      name: 'typeOfBusiness',
      queryName: '00Nb0000008NIoj',
      label: `${typeOfBusinessLabel} *`,
      options: formOptionFormat(typeOfBusinessOptions),
      ...reqDropdown,
    },
    mobile: {
      name: 'mobile',
      queryName: 'mobile',
      label: `${mobileLabel} *`,
      ...reqTextField,
    },
    faxNumber: {
      name: 'faxNumber',
      queryName: 'fax',
      label: faxNumberLabel,
      value: '',
      Component: FormText,
    },
    countryOfResidence: {
      name: 'countryOfResidence',
      queryName: 'country',
      label: `${countryOfResidenceLabel} *`,
      options: formOptionFormat(countryOfResidenceOptions),
      ...reqDropdown,
    },
    preferredVillage: {
      name: 'preferredVillage',
      queryName: '00Nb0000007q89z',
      label: `${preferredVillageLabel} *`,
      options: formOptionFormat(preferredVillageOptions),
      ...reqDropdown,
    },
    comments: {
      name: 'comments',
      queryName: 'description',
      label: `${commentsLabel}`,
      rows: '5',
      Component: FormTextarea,
    },
  }

  const fieldsSplit = [
    Object.keys(fields).slice(0, 7),
    Object.keys(fields).slice(7),
  ]

  const agreements = {
    consent: {
      name: 'consent',
      copy: consentLabel.childMarkdownRemark
        ? consentLabel.childMarkdownRemark.html
        : consentLabel,
      validation: notNull,
      errorMessage: consentValidationMessage,
    },
  }

  const { form, validateForm, handleChange, handleCheckbox } = useForm({
    ...fields,
    ...agreements,
  })

  form.confirmEmail.validation = (value) => {
    return validateEmail(value) && value === form.email.value
  }

  const [verified, setVerified] = useState(false)
  const [triggerSubmit, setTriggerSubmit] = useState(false)
  const submitFormRef = React.createRef()
  const recaptchaRef = React.createRef()

  const submitFields = {
    encoding: 'UTF-8',
    retURL: absoluteUrl(url, locOrigin),
    oid: '00Db0000000KhKW',
    recordType: '012b0000000bC3L',
  }

  Object.keys(fields)
    .filter((key) => !!fields[key].queryName)
    .map((key) => {
      const { queryName } = fields[key]
      submitFields[queryName] = form[key].value
      return form[key].value
    })

  const submitForm = () => {
    if (validateForm() && submitFormRef.current && verified) {
      submitFormRef.current.submit()
    }
  }

  const handleSubmit = () => {
    if (verified) {
      submitForm()
    } else {
      recaptchaRef.current.execute()
    }
  }

  const verifyRecaptcha = (response) => {
    if (response) {
      setVerified(true)
      setTriggerSubmit(true)
    } else {
      setVerified(false)
    }
  }

  if (triggerSubmit) {
    submitForm()
    setTriggerSubmit(false)
  }

  return (
    <Wrapper>
      <Center>
        {!!headline && <Headline>{headline}</Headline>}
        <FormGrid>
          {fieldsSplit.map((half, i) => (
            <FormColumn key={`half${i.toString()}`}>
              {half.map((key, j) => {
                const { Component, value: initialValue, ...field } = fields[key]
                const { hasError, value } = form[key]
                return (
                  <Component
                    {...field}
                    hasError={hasError}
                    value={value}
                    handleChange={handleChange}
                    key={`${field.name}${j.toString()}`}
                  />
                )
              })}
            </FormColumn>
          ))}
        </FormGrid>
        <Consent>
          <FormCheckbox
            {...agreements.consent}
            hasError={form.consent.hasError}
            value={form.consent.value}
            handleChange={handleCheckbox}
          />
        </Consent>
        <Actions>
          <form
            ref={submitFormRef}
            action="https://webto.salesforce.com/servlet/servlet.WebToLead"
            method="POST">
            {Object.keys(submitFields).map((key) => (
              <input
                type="hidden"
                name={key}
                value={submitFields[key]}
                key={key}
              />
            ))}
            <Recaptcha
              ref={recaptchaRef}
              sitekey={recaptchaKey}
              hl={locale}
              verifyCallback={verifyRecaptcha}
            />
            <SubmitBtn
              type="button"
              level="secondary"
              width="100%"
              onClick={handleSubmit}>
              {ctaLabel}
            </SubmitBtn>
          </form>
        </Actions>
      </Center>
    </Wrapper>
  )
}

TravelRegistration.propTypes = {
  commentsLabel: PropTypes.string,
  companyNameLabel: PropTypes.string,
  confirmEmailLabel: PropTypes.string,
  consentLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string,
      }),
    }),
  ]),
  consentValidationMessage: PropTypes.string,
  countryOfResidenceLabel: PropTypes.string,
  countryOfResidenceOptions: PropTypes.arrayOf(PropTypes.string),
  ctaLabel: PropTypes.string,
  emailLabel: PropTypes.string,
  emailValidationMessage: PropTypes.string,
  faxNumberLabel: PropTypes.string,
  firstNameLabel: PropTypes.string,
  headline: PropTypes.string,
  lastNameLabel: PropTypes.string,
  locale: PropTypes.string,
  locOrigin: PropTypes.string,
  mobileLabel: PropTypes.string,
  numberValidationMessage: PropTypes.string,
  preferredVillageLabel: PropTypes.string,
  preferredVillageOptions: PropTypes.arrayOf(PropTypes.string),
  recaptchaKey: PropTypes.string,
  requiredValidationMessage: PropTypes.string,
  successUrl: PropTypes.string,
  titleLabel: PropTypes.string,
  titleOptions: PropTypes.arrayOf(PropTypes.string),
  typeOfBusinessLabel: PropTypes.string,
  typeOfBusinessOptions: PropTypes.arrayOf(PropTypes.string),
  url: PropTypes.string.isRequired,
  websiteLabel: PropTypes.string,
}

TravelRegistration.defaultProps = {
  commentsLabel: '',
  companyNameLabel: '',
  confirmEmailLabel: '',
  consentLabel: '',
  consentValidationMessage: '',
  countryOfResidenceLabel: '',
  countryOfResidenceOptions: [],
  ctaLabel: '',
  emailLabel: '',
  emailValidationMessage: '',
  faxNumberLabel: '',
  firstNameLabel: '',
  headline: '',
  lastNameLabel: '',
  locale: 'en',
  locOrigin: '',
  mobileLabel: '',
  numberValidationMessage: '',
  preferredVillageLabel: '',
  preferredVillageOptions: [],
  recaptchaKey: '',
  requiredValidationMessage: '',
  successUrl: '',
  titleLabel: '',
  titleOptions: [],
  typeOfBusinessLabel: '',
  typeOfBusinessOptions: [],
  websiteLabel: '',
}

export default TravelRegistration
