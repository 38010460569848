/**
 * Formats array of villages to objects fit for use as options with FormDropdown.
 * @param {Array} arr The array of villages.
 * @return {Array} The formatted array of options as objects.
 */
export const formVillageOptions = (arr) => {
  return arr
    .sort((a, b) => {
      return a.node.name > b.node.name ? 1 : -1
    })
    .map(({ node: { name } }) => ({
      id: name,
      name,
      value: name
        .replace(/\s+[Vv]illage\s*/, '')
        .replace('La Vallee', 'La Valée'),
    }))
}

export const formatValue = (value) => {
  return value.replace(/\s+[Vv]illage\s*/, '').replace('La Vallee', 'La Valée')
}
